import React from 'react'
import './style.less';
import { Row, Col } from 'antd';
import Link from "../Link";

class ThankYouMessage extends React.Component {

    render(){

        let nextCols = 0;

        return (
            <Row className="thank-you" type="flex">
                <Col xs={{span: 24}} md={{span: 12}}>
                    <h1>Thank You</h1>
                </Col>
                <Col xs={{span: 24}} md={{span: 12}}>
                    <Row type="flex">
                        <Col xs={{span: 24}}>
                            <p className="next">Where to next?</p>
                        </Col>
                        {this.props.content && this.props.content.map((content) => {
                            nextCols++
                            return <Col xs={{span: 24}} sm={{span: 8}} key={"next-col-"+nextCols}>
                                <Link to={content.link}>
                                    <span className="title">{content.heading}</span>
                                    <span className="arrow-link">{content.text}</span>
                                </Link>
                            </Col>
                        })}
                    </Row>
                </Col>
            </Row>
        );
    }
}

export default ThankYouMessage